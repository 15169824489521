import React, {useCallback, useEffect, useRef, useState} from "react";
import {
  Navbar,
  Container,
  Stack
} from "react-bootstrap";
import {useLocation, NavLink} from "react-router-dom";
import { connect } from "react-redux";

import {GET_CURRENT_USER} from "store/actions/actionTypes";
import "styles/NavMenu.scss";
import {fetchCurrentShow} from "store/actions";
import { getShowAbbreviation } from "common";
import {convertExhibitordate} from "components/utils";
import NavLinks from './NavLinks';
import ScrollToAnchor from "components/ScrollToAnchor";
import GoogleTranslate from "components/GoogleTranslate";
import AuthNavigation from "./AuthNavigation";
import AccountApprovalStatus from "./AccountApprovalStatus";

const { REACT_APP_SERVER_BASE_URL } = process.env;

let returnToUrl = "";

const NavMenu = (props) => {
  const location = useLocation();
  let showAbbr = getShowAbbreviation();
  let fetchCurrentShow = props.fetchCurrentShow;
  const currentUser = props.user;

  const [navExpanded, setNavExpanded] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);

  const headerRef = useRef(null);

  // dynamic height calculation of header to set margin.
  const setRef = useCallback((node) => {
    if (node) {
      headerRef.current = node;
      const height = node.clientHeight;
      setHeaderHeight(height);

      // ResizeObserver to handle dynamic changes
      const resizeObserver = new ResizeObserver(entries => {
        for (let entry of entries) {
          setHeaderHeight(entry.contentRect.height);
        }
      });

      resizeObserver.observe(node);

      return () => resizeObserver.disconnect();
    }
  }, []);

  useEffect(() => {
    if (headerRef.current) {
      const height = headerRef.current.clientHeight;
      setHeaderHeight(height);
    }
  }, [headerRef.current]);

  const headerStyle = {
    marginBottom: `${headerHeight}px`,
  };

  useEffect(() => {
    if (!props.show) {
      fetchCurrentShow();
    }
  }, [fetchCurrentShow, props.show]);

  /* if location.state then set path from 'from' object otherwise set the current path excluding '/login' */
  returnToUrl =
    location.state?.from?.pathname ||
    location?.pathname.replace("/login", "") ||
    null;
  returnToUrl = encodeURI(returnToUrl);

  let {
    showName,
    showStartDate = null,
    showEndDate = null,
    venue = null,
  } = props.show;

  // useEffect(() => {
  //   const updateNavMenu = () => {
  //     // Calculate if there's enough space for show name and nav links
  //     const showNameWidth = document.querySelector('.showDetails-navbar')?.offsetWidth || 0;
  //     const navLinksWidth = document.querySelector('#basic-navbar-nav')?.offsetWidth || 0;
  //     const navbarWidth = document.querySelector('.navbarMain-container')?.offsetWidth || 0;

  //     if ((showNameWidth + navLinksWidth + 94) > navbarWidth) {
  //       setShowHamburgerMenu(true);
  //     } else {
  //       setShowHamburgerMenu(false);
  //     }
  //   };

  //   // Call the updateNavMenu function when the window is resized
  //   window.addEventListener('load', updateNavMenu);

  //   // Call the updateNavMenu function
  //   if (currentUser && Object.keys(currentUser).length > 0) {
  //     updateNavMenu();
  //   }

  //   // Cleanup function to remove the event listener
  //   return () => {
  //     window.removeEventListener('load', updateNavMenu);
  //   };

  // }, [showName, props.show, currentUser]);

  useEffect(() => {
    if (showHamburgerMenu) {
      const languageSelector = document.querySelector('iframe.skiptranslate');
      if (languageSelector) {
        languageSelector.style.left = '50%';
      }
      
      // const userDropdownMenu = document.querySelector('.mainUserDropdownMenu');
      // userDropdownMenu?.style?.left = '50%';
      // userDropdownMenu?.style?.right = 'auto';
    }
  }, [showHamburgerMenu, currentUser])

  const toggleNavbarExpand = () => {
    setNavExpanded(!navExpanded);
  }

  const showLogoLocalURL = props.show?.showLogoLocalURL || props.show?.showLogoURL || null;
  
  return (
    <div className="navmenu-wrapper" style={headerStyle}>
      <header className="site-header" ref={setRef}>
        <Navbar
          expand="lg"
          collapseOnSelect
          className={`navbar-main navbar-main-custom ${showHamburgerMenu ? ' showHamburgerMenu' : ''}`}
          expanded={showHamburgerMenu}
        >
          <Container fluid className="navbarMain-container" >
            <div className="showDetails-navbar">
              <div className="navMenuHeader row-2">
                {/* <div className="navLinksLeft">
                  <GoogleTranslate />
                </div> */}
                <NavLink
                  to={`/${showAbbr}`}
                  className={`navbar-brand`}
                  end
                >
                  <Navbar.Toggle
                    aria-controls={`basic-navbar-nav}`}
                    onClick={(e) => {
                      e.preventDefault(); // Prevent default navigation
                      toggleNavbarExpand();
                    }}
                    className={`${showHamburgerMenu ? ' show' : ' hide'}`}
                  />

                  <div className="navbarShowName">
                    {showName ? showName : null}
                  </div>
                  {showLogoLocalURL && (
                    <div className="navbarShowLogo">
                      <img
                        src={showLogoLocalURL}
                        alt="Show Logo"
                        loading="lazy"
                        onError={(event) => event.target.style.display = 'none'}
                      />
                    </div>
                  )}
                </NavLink>
                {/* <div className="navLinksRight">
                  <AuthNavigation />
                  <AccountApprovalStatus />
                </div> */}
              </div>
              <Stack gap={0} className="col-md-0 navbarShowInfo">
                {
                  showStartDate && (
                    <p className="show-date text-litegray">
                      <span title="Show Start Date">{convertExhibitordate(showStartDate)}</span>
                      {
                        convertExhibitordate(showEndDate) && (
                          <span title="Show End Date">{" "} - {convertExhibitordate(showEndDate)}</span>
                        )
                      }
                    </p>
                  )
                }
                {(venue !== null || venue !== "null") && (
                  <p className="show-location text-litegray" title="Show Venue">{venue}</p>
                )}
              </Stack>
            </div>
            <Navbar.Collapse
              id="basic-navbar-nav"
              className="justify-content-end"
              in={navExpanded}
            >
              {
                Object.keys(props?.user)?.length > 0 && (
                  <NavLinks setNavExpanded={setNavExpanded} showHamburgerMenu={showHamburgerMenu} />
                )
              }
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>

      {/* no need to touch this */}
      <ScrollToAnchor headerHeight={headerHeight} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    show: state.show,
  };
};

/* Log out the user and set the currentUser value to null */
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      window.open(
        `${REACT_APP_SERVER_BASE_URL}/auth/logout/?returnTo=${returnToUrl}`,
        "_self"
      );
      dispatch({ type: GET_CURRENT_USER, payload: null });
    },
    fetchCurrentShow: () => {
      fetchCurrentShow();
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NavMenu);
